<script>
    import 'o365.dataObject.extension.ImportTemplate.ts';
</script>
<template>
       <span @click="openFileUpload" v-bind="$attrs">
         <slot>{{$t('Import')}} {{$t('template')}}</slot>
                <input  accept="'xlsx'" type="file" ref="fileInput" class="d-none" @change="fileInputChange" />
              <OModal ref="progressmodal">
                <div class="modal-dialog">
                    <div class="modal-content ">
                        <div class="modal-header">
                            <h5 class="modal-title">
                                {{$t("Data Import/Update from Excel")}}
                            </h5>
                        
                        </div>
                        <div class="modal-body">
                            <div v-for="state in dataObject.importTemplate.importStates.states" :class="{'text-success':state.completed}">
                                {{state.message}} 
                                <div class="spinner-border spinner-border-sm me-1" role="status" v-if="!state.completed && dataObject.importTemplate.importStates.isActive(state)">
                                    <span class="sr-only"></span>
                                </div>
                                <i class="bi bi-check" v-if="state.completed"></i>
                            </div>
                            <div class="text-danger" v-if="dataObject.importTemplate.rowsFailed">
                                {{dataObject.importTemplate.rowsFailed}} {{$t('rows failed')}}.
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" v-if="dataObject.importTemplate.completed" @click = "openViewDataLog()" class="btn btn-primary btn-sm" data-bs-dismiss="modal">{{ $t('View Data Log') }}</button>
                            <button type="button" v-if="dataObject.importTemplate.completed" class="btn btn-primary btn-sm" data-bs-dismiss="modal">{{ $t('Close') }}</button>
                            <button type="button" v-if="!dataObject.importTemplate.completed" @click = "cancel()" class="btn btn-warning btn-sm" data-bs-dismiss="modal">{{ $t('Cancel') }}</button>
                        </div>
                    </div>
                </div>
            </OModal>
            <ImortDataLogModal v-if="!openDataLog" ref="refViewDataLog" :dataObject = "dataObject"/>
       </span>
     
</template>
<script setup>
    import {ref,useAttrs} from 'vue';
    import useAsyncComponent from 'o365.vue.composables.AsyncComponent.ts';
    
    
    const ImortDataLogModal = useAsyncComponent('o365.vue.components.ImortDataLogModal.vue');
    const props = defineProps(['dataObject','beforeImport','batch', 'openDataLog']);
    const fileInput = ref(null);
    const refViewDataLog = ref(null);
    const progressmodal = ref(null);
    const emit = defineEmits(['completed','error']);
    //const attrs = useAttrs();
    
     const openFileUpload=()=>{
        fileInput.value.click();
    }
    const fileInputChange =()=>{
        const vOptions = getOptions();
        vOptions.file = fileInput.value.files[0];

        progressmodal.value.show();
        props.dataObject.importTemplate.importData(vOptions);
    }

    
    const getOptions = ()=>{
        return {
            beforeImport:props.beforeImport,
            onCompleted:uploadCompleted,
            onError:uploadError,
            batch:props.batch != undefined && props.batch !== false?true:false
        }
    }

    const openViewDataLog = () =>{
        if(props.openDataLog ){
            props.openDataLog.call();
            return;
        }

        refViewDataLog.value.modalRef.show();
    }


    const cancel = () =>{
        props.dataObject.importTemplate.cancel();
         console.log('cancelled')
        fileInput.value.value = "";
    }

    const uploadCompleted= (pProgress)=>{
        //emit("completed",pProgress)
        console.log('completed',pProgress)
        fileInput.value.value = "";

    }
    const uploadError= (pError)=>{
        emit("error",pError)
        progressmodal.value.hide();
        fileInput.value.value = "";

    }
   

</script>